.processmaindiv {
    padding-block: 2rem;
}

.processmaindiv h1 {
    text-align: center;
    font-family: var(--font-AeonikTRIAL);
    font-weight: 400;
    color: var(--orangecolor);
    font-size: 6rem;

}

.parentdivofstep {

    /* width: 40%; */
    padding-inline: 4rem;
    padding-top: 1.5rem;
}


.mainflexdivofsteps {
    display: flex;
    flex-wrap: wrap;
    padding-inline: 8rem;
}

.parentdivwidth {
    width: 33%;
}

.rectanglepicandstepdiv {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.rectanglepicandstepdiv h3 {
    color: var(--orangecolor);
    font-family: AeonikTRIAL-Bold;
    font-size: 1.3rem;
}



.stepheadingdiv h2 {
    color: black;
    font-family: AeonikTRIAL-Bold;
    font-size: 1.6rem;
    padding-block: 0.6rem;
}


.stepheadingdiv p {
    color: #727272;
    font-family: AeonikTRIAL-Regular;
    font-size: 1.5rem;

}








/* phone media querires */

@media screen and (max-width:1000px) {

    .parentdivofstep{
        padding-inline: 2rem;
    }

}
@media screen and (max-width:850px) {

    .mainflexdivofsteps {
        padding-inline: 2rem;
    }

}



@media screen and (max-width:750px) {

    .parentdivwidth {
        width: 100%;
    }

}

@media screen and (max-width:470px) {



    .processmaindiv h1 {

        font-size: 4rem;

    }
        .rectanglepicandstepdiv h3 {
            font-size: 1.6rem;
        }
        
        
        
        .stepheadingdiv h2 {
            font-size: 1.8rem;
        }
        
        
        .stepheadingdiv p {
            font-size: 1.5rem;
        
        }
        
}



@media screen and (max-width:410px) {

    .lightborder {

        gap: 2rem;
    }

    .processmaindiv h1 {
        font-size: 3rem;
    }


}