.talentdiv {
  text-align: center;
  padding-top: 1rem;
}

.talentdiv h1 {
  font-family: AeonikTRIAL-Bold;
  font-weight: 700;
  font-size: 19rem;
  color: transparent;
  /* Make text transparent */
  display: inline;
  background: linear-gradient(180deg, #E9620B 22.22%, rgba(233, 98, 11, 0.00) 111.11%);
  background-clip: text;
  /* Clip the background to the text */

}


.swiper-wrapper {
  height: 400px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 3rem;
}

.swiper {
  width: 100%;
  height: 100%;
}



.swiperimage {
  width: 330px;
  height: 370px;
  position: relative;
  border-radius: 23.285px;
  background: linear-gradient(225deg, #F3AC7F 1.74%, #FBE8DC 100%);
}

.imagebelow {
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 0px 0px 23.285px 23.285px;
  width: 100%;
  background: linear-gradient(38deg, #E9620B 0%, rgba(233, 98, 11, 0.73) 71.76%);
  height: 100px;
  flex-direction: column;
  text-align: justify;
  display: flex;
  padding: 10px;
  color: white;
  font-family: AeonikTRIAL-Regular;
  font-weight: 400;
}

.swiper-slide {
  margin: 0px !important;
}

.imagebelow div:nth-of-type(1) {
  font-size: 27px;
  font-family: AeonikTRIAL-Regular;
  font-weight: 400;
}

a{
  text-decoration: none;
  color: #fff;
}

.imagebelow div:nth-of-type(2) {
  font-size: 18px;
  font-family: AeonikTRIAL-Regular;
  font-weight: 400;
  padding-block: 0.6rem;
}

.imagebelow div:nth-of-type(3) {
  font-size: 16px;
  font-family: AeonikTRIAL-Regular;
  font-weight: 400;
}



.maindivoftalentswiper {
  margin-inline: 10rem !important;
  margin-top: -6rem;
}


.swiper-slide {

  font-size: 18px;
  background: #fff;
  height: 350px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide {
  transition: transform 0.3s;
  
}

.swiper-slide-active-custom {
  transform: scale(1);

}

.swiper-slide-next-custom, .swiper-slide-prev-custom {
  transform: scale(0.8);
}


.swiper-slide img {
  display: block;
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}

.maindivoftalentswiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100% ;
  object-fit: cover;
}



.swiper {
  width: 100%;
  height: 400px;
  margin: 0px auto;
}

/* inspect tool stting */

.swiper-button-prev {
  color: #fff !important;
  background-color: #E9620B;
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.swiper-button-prev:after {
  font-size: 22px !important;
}

.swiper-button-next:after {
  /* color: #fff !important;
  background-color: #E9620B;
  height: 40px;
  width: 60px;
  border-radius: 100%;
  font-size: 20px !important;
  line-height: 2; */

  padding-left: 1.5rem;
  color: #fff !important;
  background-color: #E9620B;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  font-size: 18px !important;
  line-height: 2;
  margin-left: -1rem;
}

.swiper-pagination {
  width: 100%;
  /* Set the width */
  height: 40px !important;
  /* Set the height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-pagination .swiper-pagination-fraction {
  font-size: 16px !important;
  /* Adjust the font size */
  color: #fff !important;
  /* Adjust the text color */
}

.swiper-pagination-fraction {
  display: none !important;
}


/* asdasd */


/* YourCSSFile.css */



.slider-container {
  width: 90%;
  min-width: 300px;
  max-width: 650px;
  overflow: hidden;
  border: 2px solid #c4c4c4;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
}

.item-container {
  width: 100%;
  height: 300px;
  margin: 0 auto;
  perspective: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-style: preserve-3d;
  position: relative;
}

.item {
  flex-basis: 1;
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  background-color: #3498db;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
  filter: blur(2px);
}

.medianValue {
  background-color: #e74c3c;
  box-shadow: 3px 3px 10px #bbb, -3px 3px 10px #bbb;
  width: 150px;
  height: 150px;
  transform: translateZ(0px) rotateY(0);
  filter: none;
  margin: 5px;
  z-index: 10;
}

.firstLeft {
  transform: translateZ(-180px) translateX(0px) rotateY(35deg);
}

.firstRight {
  transform: translateX(-0px) translateZ(-180px) rotateY(-35deg);
}

/* Rest of your CSS code */


@media screen and (max-width:1920px) {

  .maindivoftalentswiper .swiper{
    width: 55%;
  }
}


@media screen and (max-width:1800px) {

  .maindivoftalentswiper .swiper{
    width: 62%;
  }
}

@media screen and (max-width:1610px) {

  .maindivoftalentswiper .swiper{
    width: 68%;
  }
}
@media screen and (max-width:1550px) {

  .maindivoftalentswiper .swiper{
    width: 74%;
  }
}


@media screen and (max-width:1450px) {

  .maindivoftalentswiper .swiper{
    width: 100%;
  }
  .maindivoftalentswiper {
    margin-inline: 24rem !important;
    margin-top: -6rem;
  }
  
}
@media screen and (max-width:1400px) {

  .maindivoftalentswiper {
    margin-inline: 10rem !important;
    margin-top: -6rem;
  }
  .maindivoftalentswiper .swiper{
    width: 80%;
  }
}

@media screen and (max-width:1350px) {

  .maindivoftalentswiper .swiper{
    width: 90%;
  }
}

@media screen and (max-width:1200px) {

  .maindivoftalentswiper .swiper {
    width: 65%;
}
}



@media screen and (max-width:850px) {

  .maindivoftalentswiper {
    margin-inline: 0rem !important;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width:770px) {

  .imagebelow div:nth-of-type(1) {
    font-size: 18px;
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
  }

  .imagebelow {
    padding: 3px;
  }

}

/* phone media querires */


@media screen and (max-width:500px) {

  .maindivoftalentswiper .swiper {
    width: 100%;
}
}


@media screen and (max-width:470px) {

  .talentstackdiv h1 {
    font-size: 4rem;
  }

  .maindivoftalentswiper {
    margin-inline: 0rem !important;
    margin-top: -6rem;
  }

  .imagebelow div:nth-of-type(2) {
    font-size: 12px;
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
  }

  .maindivoftalentswiper {

    margin-top: 0rem;
}

.imagebelow {
  padding: 3px;
  padding-left: 2rem;
}

}