.ourclientmaindiv {
    height: 100vh;
}

.ourclientmaindiv h1 {
    color: var(--orangecolor);
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 10rem;
    text-align: center;
    padding-top: 2rem;
}


.brandoralogodiv {
    margin-top: -7rem;
}

.brandoralogodiv img {
    width: 150px;
    margin-left: 10rem;
}

.clientimgdiv {
    margin-top: 2rem;
    margin-left: 6rem;
    border-left: 1px solid var(--orangecolor);
    border-top: 1px solid var(--orangecolor);
    border-bottom: 1px solid var(--orangecolor);
    border-right: 1px solid transparent;
    height: 350px;
    width: 500px;
    border-top-left-radius: 2.5rem;
    border-bottom-left-radius: 2.5rem;
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
    display: flex;
    gap: 5rem;
    /* flex-wrap: wrap; */
}


.ourclientmaindiv .swiper-slide {
    /* border: 2px solid red !important; */
    width: 550px !important;
}

.ourclientmaindiv .swiper-slide img {
    display: block;
    /* width: 100%; */
    /* height: 100%; */
    object-fit: cover;
}

.ourclientmaindiv .swiper-wrapper {
    gap: 0rem;
}

.clientmaindivflex {
    display: flex;
    flex-wrap: wrap;
}

.leftsideofclientdiv {
    /* width: 40%; */
    /* border: 1px solid red; */
    padding-left: 2rem;
    padding-top: 3rem;
}

.leftsideofclientdiv img {
    width: 150px;
}

.leftsideofclientdiv p {
    color: black;
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 1.4rem;
    padding-top: 5rem;

}

.leftsideofclientdiv span {
    color: #E9620B;
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 1.4rem;


}

.leftsideofclientdiv small {
    color: var(--orangecolor);
    font-family: AeonikTRIAL-Regular;
    font-weight: 700;
    font-size: 1.4rem;


}

.leftsideofclientdiv h2 {
    color: var(--orangecolor);
    font-family: AeonikTRIAL-Regular;
    font-weight: 700;
    font-size: 3rem;
    padding-top: 3rem;
}

.rightsideofclientdiv img {
    height: 349px;
    width: 228px;
    object-fit: cover;
}

.rightsideofclientdiv {
    /* border: 2px solid black; */
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(77deg, #E9620B 0%, rgba(233, 98, 11, 0.55) 100%); */
    background-color: black;
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
}

.rightsideofclientdiv .playicon {
    /* border: 2px solid blue; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10rem;
    cursor: pointer;
    color: #fff;


}

.rightsideofclientdiv video {
    height: 349px;
    width: 225px;
    object-fit: cover;
    opacity: 0.2;
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;

}

@media screen and (max-width:1920px) {

    .ourclientmaindiv h1 {

        font-size: 12rem;
        padding-top: 2rem;
    }

    .clientmaindivflex {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 4rem;
    }

    .ourclientmaindiv .swiper-slide {
        width: 570px !important;
    }

    .clientimgdiv {

        width: 450px;

    }

    .ourclientmaindiv .swiper-wrapper {
        margin-left: 70px;
    }
}

@media screen and (max-width:1440px) {
    .ourclientmaindiv h1 {

        font-size: 12rem;
        padding-top: 5rem;
    }

    .clientmaindivflex {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        margin-top: 0rem;
    }

    .ourclientmaindiv .swiper-slide {
        width: 510px !important;
    }

    .clientimgdiv {

        width: 500px !important;

    }

    .ourclientmaindiv .swiper-wrapper {
        margin-left: 0px;

    }
}

@media screen and (max-width:1180px) {
    .ourclientmaindiv {
        height: 155vh;
    }
}

@media screen and (max-width:1025px) {
    .ourclientmaindiv {
        height: 200vh;
    }

    .talentdiv h1 {
        font-size: 15rem;
    }
}

@media screen and (max-width:850px) {


    .rightsideofclientdiv {
        display: none;
    }

    .rightsideofclientdiv video {
        display: none;
    }


    .ourclientmaindiv {
        height: 100vh;
    }

    .talentdiv h1 {
        font-size: 10rem;
    }

    .brandoralogodiv img {
        width: 150px;
        margin-left: 2rem;

    }

    .ourclientmaindiv h1 {

        font-size: 8rem;

    }

    .brandoralogodiv img {
        width: 100px;
    }

    .rightsideofclientdiv img {
        display: none;
    }

    .clientimgdiv {
        margin-top: 5rem;

        border-left: 1px solid var(--orangecolor);
        border-top: 1px solid var(--orangecolor);
        border-bottom: 1px solid var(--orangecolor);
        border-right: 1px solid var(--orangecolor);
        gap: 2rem;
        /* flex-wrap: wrap; */
    }

    #modaldivcenter {
        display: flex;
        justify-content: center;
        height: 0 !important;
    }


}

@media screen and (max-width:600px) {
    .ourclientmaindiv h1 {
        font-size: 6rem;
        padding-block: 2rem;
    }

    .talentdiv h1 {
        font-size: 8rem;
    }
}

/* phone media querires */

@media screen and (max-width:500px) {
    .clientimgdiv {
        margin-left: 1rem;
        margin-top: 7rem;
        gap: 0;
        height: 325px;
    }

    .brandoralogodiv img {
        display: none;
    }
}

@media screen and (max-width:470px) {
    .clientimgdiv {
        margin-left: 1rem;
        margin-top: 7rem;
        gap: 0;
        height: 300px;
    }

    .clientimgdivmodal{
        margin-left: 0rem !important;
    }

    .rightsideofclientdiv img {
        height: 242px;
        width: 160px;
        display: none;
    }

    .swiper-slide {
        height: 310px;

    }

    .leftsideofclientdiv {
        padding-left: 1rem;
    }

    .ourclientmaindiv {
        height: 65vh;
    }

    .leftsideofclientdiv h2 {

        font-size: 2rem;
        padding-top: 2rem;

    }

    .ourclientmaindiv .modal-content{
        margin-top: 10rem;
    }

    .leftsideofclientdiv p {

        padding-top: 2rem;
    }

    .clientimgdiv {
        border-left: 1px solid var(--orangecolor);
        border-top: 1px solid var(--orangecolor);
        border-bottom: 1px solid var(--orangecolor);
        border-right: 1px solid var(--orangecolor);
    }

    .leftsideofclientdiv img {
        width: 100px;
    }

    .ourclientmaindiv .swiper-slide {
        width: 250px !important;
    }
}



@media screen and (max-width:400px) {
    .leftsideofclientdiv h2 {

        font-size: 2rem;

    }

    .ourclientmaindiv {
        height: 100vh;
    }

    .leftsideofclientdiv p {
        padding-top: 3rem;
    }

    .clientimgdiv {
        border-left: 1px solid var(--orangecolor);
        border-top: 1px solid var(--orangecolor);
        border-bottom: 1px solid var(--orangecolor);
        border-right: 1px solid var(--orangecolor);
    }

    .leftsideofclientdiv img {
        width: 100px;
    }
}

@media screen and (max-width:375px) {
    .ourclientmaindiv {
        height: 114vh;
    }

    .leftsideofclientdiv p {
        padding-top: 2rem;
    }
}



/* the modal is herer */
/* Styles for the modal popup */


.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: -170px;
    width: 100%;
    height: 143%;
    background-color: rgba(255, 255, 255, 0.9);
    /* Background with opacity */
    animation: slideDown 0.3s ease;
}

.modal-content {
    padding: 20px;
    border-radius: 5px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
    width: 100%;
    /* max-width: 800px; */
    height: 100%;
    max-height: 600px;
    overflow-y: auto;
    animation: slideIn 0.5s ease;
    /* opacity: 0.9;
    background-color: #fff; */
}

#modaldivcenter {
    display: flex;
    justify-content: center;
    height: 75vh;


}

.close {
    color: #4b4b4b;
    float: right;
    font-size: 45px;
    margin-bottom: 2rem;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.read-more-link {
    color: blue;
    cursor: pointer;
}

.read-more-link:hover {
    text-decoration: underline;
}

.clientimgdivmodal {
    margin-top: 4rem;
    margin-left: 6rem;
    border-left: 1px solid var(--orangecolor);
    border-top: 1px solid var(--orangecolor);
    border-bottom: 1px solid var(--orangecolor);
    border-right: 1px solid transparent;
    height: 440px;
    width: 843px;
    border-top-left-radius: 2.5rem;
    border-bottom-left-radius: 2.5rem;
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
    display: flex;
    gap: 5rem;
    border: 1px solid var(--orangecolor);
    background-color: #fff;
    /* White background */
}

.clientimgdivmodal .leftsideofclientdiv {
    padding-top: 10rem;
}


/* Animation for modal slide in */
@keyframes slideIn {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

/* Animation for modal slide down */
@keyframes slideDown {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}



/* the sec slider */

#sanapic {
    height: 40px;
    width: 110px;
}

#heading2slidertext {
    font-size: 2.5rem;
}