 .ctamaindiv {
     border: 1px solid var(--orangecolor);
     background-color: var(--orangecolor);

 }


 .mainflexdivcta {
     display: flex;
     flex-wrap: wrap;
     padding-inline: 5rem;
     padding-top: 5rem;
     padding-bottom: 10rem;
     gap: 5rem;
 }


 .ctafstwhitediv {
     background-color: #fff;
     border-radius: 2rem;
     padding-inline: 5rem;
     padding-block: 2rem;
     flex: 1;


 }

 .ctafstwhitediv p {
     text-align: center;
     font-size: 2rem;
     font-family: AeonikTRIAL-Regular;
     font-weight: 400;
 }

 .ctafstwhitediv h1 {
     text-align: center;
     color: var(--orangecolor);
     font-size: 9rem;
     font-family: AeonikTRIAL-Regular;
     font-weight: 400;
 }


 .ctafstwhitediv h2 {
     text-align: center;
     font-size: 2rem;
     font-family: AeonikTRIAL-Regular;
     font-weight: 400;
     padding-top: 1.5rem;
 }

 .ctafstwhitediv button {
     background-color: var(--orangecolor);
     color: rgb(255, 255, 255);
     font-family: AeonikTRIAL-Regular;
     font-weight: 400;
     border: none;
     outline: none;
     width: 100%;
     padding: 3rem;
     border-radius: 1rem;
     font-size: 2rem;
     cursor: pointer;
     margin-top: 2rem;
 }

 .ctasecwhitediv {
     background-color: black;
     color: #fff;
     border-radius: 2rem;
     padding-inline: 5rem;
     padding-block: 2rem;
     flex: 1;


 }

 .ctasecwhitediv p {
     text-align: center;
     font-size: 2rem;
     font-family: AeonikTRIAL-Regular;
     font-weight: 400;
 }

 .ctasecwhitediv h1 {
     text-align: center;
     color: var(--orangecolor);
     font-size: 9rem;
     font-family: AeonikTRIAL-Regular;
     font-weight: 400;
     color: #fff;
 }


 .ctasecwhitediv h2 {
     text-align: center;
     font-size: 2rem;
     font-family: AeonikTRIAL-Regular;
     font-weight: 400;
     padding-top: 2rem;
 }

 .ctasecwhitediv button {
     background-color: #fff;
     color: var(--orangecolor);
     font-family: AeonikTRIAL-Regular;
     font-weight: 400;
     border: none;
     outline: none;
     width: 100%;
     padding: 3rem;
     border-radius: 1rem;
     font-size: 2rem;
     cursor: pointer;
     margin-top: 2rem;
 }


 .copytextmain {
     position: relative;
 }

 .copytextdivp {
     position: absolute;
     top: 30px;
     right: 50px;
     font-family: 'AeonikTRIAL-Regular';
     font-size: 14px;
     color: '#fff';
     background-color: rgba(0, 0, 0, 0.5);
     padding: 5px;
     border-radius: 5px;
 }


 @media screen and (max-width:500px) {

    .mainflexdivcta  , 
    .ctafstwhitediv,
    .ctasecwhitediv{
        padding-inline: 2rem;

    }

    

    .ctafstwhitediv p , .ctasecwhitediv p {
        font-size: 1.6rem;
    }

    .ctafstwhitediv h1 , .ctasecwhitediv h1 {
        font-size: 7rem;
    }

    .ctafstwhitediv button {
        padding: 2rem;
    }

    .ctasecwhitediv button {
        padding: 2rem;
        font-size: 1.5rem;
    }

}
 @media screen and (max-width:460px) {


    .mainflexdivcta {
        padding-inline: 2rem;

    }

    .ctafstwhitediv p , .ctasecwhitediv p {
        font-size: 1.4rem;
    }

    .ctafstwhitediv h1 , .ctasecwhitediv h1 {
        font-size: 5rem;
    }

    .ctafstwhitediv button {
        padding: 1.5rem;
    }

    .ctasecwhitediv button {
        padding: 2rem;
        font-size: 1.5rem;
    }

    .copytextdivp{
        right: 10px;
    }

}