.stepmaindiv {

    background:
        url('../../../Assets/Img/boy.svg') center center no-repeat,
        linear-gradient(225deg, #F3AC7F 1.74%, #FBE8DC 100%);
    background-position: center;
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
}

.stepmaindiv h1 {
    color: var(--orangecolor);
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 8.5rem;
    padding-top: 8rem;
    padding-left: 9rem;
}


.mainstepdiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 8rem;
    /* margin: auto;
    width: 80%; */
    margin-inline: 10rem;

}

.stepcards {
    /* border: 1px solid red; */
    padding-inline: 3rem;
    background-color: var(--orangecolor);
    color: #fff;
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 1.5rem;
    width: 270px;
    height: 230px;
    border-radius: 1rem;
    padding-block: 1.5rem;

}

.stepcards h2 {
    font-family: AeonikTRIAL-Regular;
    font-size: 3.5rem;
    font-weight: 700;


}

.stepcards p {
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 1.5rem;
    padding-top: 1.5rem;

}

#step1maindiv {
    padding-top: 7rem;
}

.stepandhiremaindiv {
    display: flex;
    gap: 1.8rem;
    color: var(--orangecolor);
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 7.5rem;
    padding-left: 9rem;
}


.seccard {
    background-color: #fff;
}

.seccard h2 {
    color: var(--orangecolor);
    font-weight: 700;
    font-family: AeonikTRIAL-Regular;
}

.seccard p {
    color: black;
    font-family: AeonikTRIAL-Regular;
    font-size: 1.5rem;
    font-weight: 400;
}


.thirdcard {
    background-color: black;
}




/* media queries */

@media screen and (max-width:1920px) {
    
    .mainstepdiv {
        justify-content: center;
        gap: 9rem;
        padding-top: 13rem;
    }

    .stepandhiremaindiv {
        font-size: 9.5rem;
 
    }
    .stepcards {

    width: 345px;
    height: 300px;

    }
    .stepcards h2 {
        font-size: 4.5rem;
    }
    .stepcards p {
        font-size: 1.8rem;

    }
}
@media screen and (max-width:1440px) {
    .mainstepdiv {
        justify-content: space-between;
        font-size: 7.5rem;
        padding-top: 9rem;
    }

    .stepandhiremaindiv {
        font-size: 7.5rem;
 
    }

    .stepcards {

        width: 290px;
        height: 245px;

    }
    .stepcards h2 {
        font-size: 3.5rem;
    }
    .stepcards p {
        font-size: 1.5rem;

    }
}


@media screen and (max-width:1550px) {
    .mainstepdiv {
        justify-content: space-between;
        font-size: 7.5rem;
        padding-top: 9rem;
    }

    .stepandhiremaindiv {
        font-size: 7.5rem;
 
    }

    .stepcards {

        width: 290px;
        height: 245px;

    }
    .stepcards h2 {
        font-size: 3.5rem;
    }
    .stepcards p {
        font-size: 1.5rem;

    }
}



@media screen and (max-width:1270px) {
    .mainstepdiv {
        gap: 5rem;
    }
}

@media screen and (max-width:1180px) {
    .mainstepdiv {
        margin-inline: 7rem;
    }
}

@media screen and (max-width:1050px) {
    .stepmaindiv {
        height: 145vh;
    }

    .mainstepdiv {
        justify-content: center;
    }
}

@media screen and (max-width:850px) {
    .stepmaindiv {
        background: linear-gradient(225deg, #F3AC7F 1.74%, #FBE8DC 100%) !important;
        background-image: none;
        height: 120vh;
    }

}

@media screen and (max-width:700px) {

    .mainstepdiv {
        justify-content: center;
        gap: 2rem;
        padding-top: 3rem;

    }

    .stepandhiremaindiv {
        font-size: 5rem;
    }

    .stepcards h2 {
        font-family: AeonikTRIAL-Regular;
        font-size: 2.5rem;
        font-weight: 700;
    }

    .stepcards p {
        font-family: AeonikTRIAL-Regular;
        font-weight: 400;
        font-size: 1.3rem;
        padding-top: 1.5rem;
    }

    .stepcards {
        /* border: 1px solid red; */

        width: 238px;
        height: 220px;

    }
}


@media screen and (max-width:600px) {

    .stepmaindiv {
        height: 130vh;
    }
}

/* phone media querires */

@media screen and (max-width:470px) {

    .stepmaindiv {
        height: 111vh;
    }


}

@media screen and (max-width:400px) {

    .stepmaindiv {
        height: 120vh;
    }


}

@media screen and (max-width:375px) {

    .stepmaindiv {
        height: 143vh;
    }


}

@media screen and (max-width:330px) {

    .mainstepdiv {
        margin-inline: 4rem;
    }

    .stepandhiremaindiv {


        padding-left: 4rem;
    }


}


@media screen and (max-width:500px) {

    .stepmaindiv {
        background: linear-gradient(225deg, #F3AC7F 1.74%, #FBE8DC 100%) !important;
        background-image: none;
    }


}