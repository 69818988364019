.whyusmaindiv {
    height: 100vh;
}

.whyusmaindiv h1 {
    color: var(--orangecolor);
    font-family: AeonikTRIAL-Regular;
    font-size: 7.5rem;
    font-weight: 400;
    text-align: center;
    padding-block: 1rem;
}

.waysimgdiv {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-inline: 5rem;
    gap: 8rem;

}

.waysimgdiv img {
    width: 320px;
}


.way1div {
    position: relative;
}

.way1text {
    position: absolute;
    top: 45%;
    padding-inline: 2rem;
}

.way1text h2 {
    color: var(--orangecolor);
    font-family: AeonikTRIAL-Regular;
    font-weight: 700;
    font-size: 4.5rem;
}

.way1text p {
    color: black;
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 1.4rem;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
}



@media screen and (max-width:1180px) {
    .waysimgdiv {
        gap: 3rem;
    }
}

@media screen and (max-width:1122px) {
    .waysimgdiv {
        gap: 2rem;
        padding-inline: 5rem;
        justify-content: left;
    }
}

@media screen and (max-width:1080px) {
    .whyusmaindiv {
        height: 155vh;
    }
}
@media screen and (max-width:850px) {
    .whyusmaindiv {
        height: 99vh ;
    }
}

@media screen and (max-width:1250px) {
    .whyusmaindiv {
        height: 110vh;
    }
}

@media screen and (max-width:700px) {
    .whyusmaindiv h1 {

        font-size: 5.5rem;

    }

    .way1text h2 {
        color: var(--orangecolor);
        font-family: AeonikTRIAL-Regular;
        font-weight: 700;
        font-size: 3rem;
    }

    .waysimgdiv img {
        width: 269px;
    }
}



@media screen and (max-width:600px) {

    .whyusmaindiv h1 {
        font-size: 5rem;
        padding-top: 0rem;
    }

    .waysimgdiv {
        justify-content: center;
    }

    .whyusmaindiv {
        height: 226vh;
    }

}



/* phone media querires */

@media screen and (max-width:470px) {
    .whyusmaindiv h1 {
        font-size: 4rem;
    }

    .whyusmaindiv {
        height: 140vh;
    }

}



@media screen and (max-width:400px) {
    .whyusmaindiv {
        height: 155vh;
    }

}

@media screen and (max-width:375px) {
    .whyusmaindiv {
        height: 188vh;
    }

}
@media screen and (max-width:330px) {
    .whyusmaindiv h1 {
        font-size: 3rem;
    }

}