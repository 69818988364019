
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body{
    overflow-x: hidden !important;
    user-select: none;
}

:root{
    --orangecolor : #E9620B;
    
}


html{
    font-size: 62.5%;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

@font-face {
    font-family: AeonikTRIAL-Regular;
    src: url('./Assets/Fonts/AeonikTrials/AeonikTRIAL-Regular.ttf');
  }
@font-face {
    font-family: AeonikTRIAL-Light;
    src: url('./Assets/Fonts/AeonikTrials/AeonikTRIAL-Light.ttf');
  }


@font-face {
    font-family: aeoniktrial-light;
    src: url('./Assets/Fonts/aeoniktrial-light.ttf');
  }

@font-face {
    font-family: AeonikTRIAL-Bold;
    src: url('./Assets/Fonts/AeonikTrials/AeonikTRIAL-Bold.ttf');
  }