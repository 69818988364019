.bgpic {
    background: linear-gradient(77deg, #E9620B 0%, rgba(233, 98, 11, 0.55) 100%);
    height: 100vh;
    background-size: cover;
    background-position: center;
}

.bgpic video {
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.maindiv {
    text-align: center;
    padding-top: 8rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 3rem;

}

.maindiv .collwaysimg {
    height: 100px;
    width: 100px;
}

.maindiv h1 {
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 5.8rem;
    color: #ffff;
}

.headingandparagraphmaindiv {
    padding-block: 5rem;
    padding-bottom: 10rem;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-inline: 5rem;

}

.headingandparagraphmaindiv h1 {
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 7rem;
    color: #ffff;
}

.headingandparagraphmaindiv p {
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 2.2rem;
    color: #ffff;
    line-height: 3rem;
}

.headingandparagraphmaindiv button {
    margin-top: 1rem;
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 1.7rem;
    color: var(--orangecolor);
    border: none;
    border-radius: 1.5rem;
    padding: 0.9rem 2.5rem;
    display: flex;
    gap: 0.8rem;
    align-items: center;
    cursor: pointer;
}

.headingandparagraphmaindiv .arrowbtnicon {
    width: 10px;
    height: 10px;
}

.navbarposi {
    position: fixed;
    width: 70%;
    top: 88vh;
    right: 15% !important;
    z-index: 10;
}



@media screen and (max-width:1920px) {

    .headingandparagraphmaindiv h1 {
        font-size: 9rem;
    }

    .headingandparagraphmaindiv p {
        font-size: 3rem;
        line-height: 4rem;
    }

    .maindiv h1 {
        font-family: AeonikTRIAL-Regular;
        font-weight: 400;
        font-size: 8rem;
        color: #ffff;
    }

    .problemsolutionmaindiv h6 {
        color: #fff;
        font-family: AeonikTRIAL-Regular;
        font-weight: 400;
        font-size: 4.5rem;
        line-height: 7.1rem;
        display: flex;
        justify-content: center;
        text-align: left;
    }

    .headingandparagraphmaindiv{
        padding-block: 14rem;
        padding-inline: 14rem;
    }

    
}


@media screen and (max-width:500px) {

    .headingandparagraphmaindiv{
        padding-block: 8rem;
        padding-inline: 3rem !important;
    }

}

@media screen and (max-width:1550px) {

    .headingandparagraphmaindiv{
        padding-block: 10rem;
        padding-inline: 10rem;
    }

    .headingandparagraphmaindiv h1 {
        font-size: 8rem;
    }
    .headingandparagraphmaindiv p {
        font-size: 3rem;
        line-height: 4rem;
    }
}


@media screen and (max-width:1440px) {

    .headingandparagraphmaindiv h1 {
        font-size: 7rem;
    }

    .headingandparagraphmaindiv p {
        font-size: 2.5rem;
    }

    .maindiv h1 {
        font-family: AeonikTRIAL-Regular;
        font-weight: 400;
        font-size: 5.8rem;
        color: #ffff;
    }

    .problemsolutionmaindiv h6 {
        color: #fff;
        font-family: AeonikTRIAL-Regular;
        font-weight: 400;
        font-size: 3.5rem;
        line-height: 4.1rem;
        display: flex;
        justify-content: center;
        text-align: left;
    }

}



@media screen and (max-width:1080px) {

    .navbarposi {
        width: 75%;
    }
}




@media screen and (max-width:1025px) {

    .headingandparagraphmaindiv h1 {
        font-size: 6rem;
    }

    .headingandparagraphmaindiv p {
        font-size: 2rem;
    }
}


/* phone media querires */
@media screen and (min-width: 370px) and (max-width: 470px) {
    .maindiv h1 {
        font-size: 4rem;
    }

    .maindiv .collwaysimg {
        height: 67px;
        width: 66px;
    }
    .navbarposi {
        right: 5% !important;
    }
}

@media screen and (max-width: 330px) {


    .navbarposi {
        right: 18% !important;
    }
}