.guaranteeparentdiv {
    background: rgba(233, 98, 11, 0.08);
    height: 107vh;
}

.guaranteeparentdiv h1 {
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    color: var(--orangecolor);
    font-size: 12rem;
}

.guarentecardparent {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
    margin-top: -4rem;
    z-index: 2;
    position: relative;
}

.gurantecards {
    background-color: #fff;
    width: 230px;
    height: 200px;
    border-radius: 2rem;
    /* padding-block: 1.5rem; */
    /* padding-inline: 1.5rem; */
}

.gurantecards h2 {
    font-family: AeonikTRIAL-Bold;
    font-weight: 700;
    color: black;
    font-size: 3rem;
    padding-top: 0.5rem;
}

.gurantecards p {
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    color: black;
    font-size: 1.5rem;
    padding-top: 1.5rem;

}


.guaranteeparentdiv .swiper-wrapper{
    height: 440px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 3rem;
}

.guaranteeparentdiv .swiper-slide {
    height: 230px;
   
}


.swiperslidediv{
    height: 210px;
    width: 250px !important;
    border-radius: 2rem;
}

.maindivofg .swiper{
    margin-left: 7rem;
    margin-top: -2rem;
    overflow: hidden !important;

}

.maindivofg .swiper-slide{

    width: 28rem !important;
}


.swiper-slide{
    /* height: 240px !important; */
    cursor: pointer;
    
}



.maindivofg{
  
    margin-top: -13rem;
    /* margin-left: 1rem; */

}




@media screen and (max-width:1920px) {

    .guaranteeparentdiv h1 {
        font-size: 16rem;
    }

    .guarentecardparent {
        justify-content: center;
    }

    .maindivofg .swiper-slide {
        width: 40rem !important;
    }

}



@media screen and (max-width:1440px) {
    .guaranteeparentdiv h1 {
        font-size: 12rem;
    }

    .guarentecardparent {
        justify-content: left;
    }

    .maindivofg .swiper-slide {
        width: 30rem !important;
    }
}
@media screen and (max-width:1550px) {
    .guaranteeparentdiv h1 {
        font-size: 12rem;
    }

    .guarentecardparent {
        justify-content: left;
    }

    .maindivofg .swiper-slide {
        width: 32rem !important;
    }

    .guaranteeparentdiv .swiper-wrapper {
        gap: 5rem;
    }
}

@media screen and (max-width:1400px) {
 
    .guaranteeparentdiv .swiper-wrapper {
        gap: 3rem;
    }

    .maindivofg .swiper-slide {
        width: 30rem !important;
    }
}



@media screen and (max-width:1180px) {
    .guaranteeparentdiv {
        height: 120vh;

    }
}

@media screen and (max-width:1025px) {
    .guaranteeparentdiv {
        height: 150vh;

    }
}

@media screen and (max-width:700px) {
    .guaranteeparentdiv h1 {
        font-family: AeonikTRIAL-Regular;
        font-weight: 400;
        color: var(--orangecolor);
        font-size: 8rem;
    }

    .maindivofg .swiper{
        margin-left: 0rem;
        overflow: hidden !important;

    
    }
    
}

@media screen and (max-width:850px) {
   
    .maindivofg .swiper{
        margin-left: 0rem;
        overflow: hidden !important;

    
    }

    .guaranteeparentdiv {
        height: 90vh;
    }

}

@media screen and (max-width:600px) {
    .gurantecards h2 {

        font-size: 2rem;

    }
    .gurantecards p {

        font-size: 1.4rem;

    }

    .gurantecards {

        width: 211px;
        height: 196px;
        padding-inline: 2rem;
        padding-block: 0rem;

    }

    .maindivofg .swiper{
        margin-left: 0rem;
        overflow: hidden !important;

    
    }
    
}


/* phone media querires */

@media screen and (max-width:470px) {

    .guaranteeparentdiv {
        height: 225vh;
    }

    .guarentecardparent {
        justify-content: center;
    }

    .guaranteeparentdiv {
        height: 80vh;
    }

    .maindivofg .swiper-slide {
        width: 22rem !important;
    }

    .guaranteeparentdiv .swiper-wrapper{
        height: 470px;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        gap: 3rem;
    }
    
    .guaranteeparentdiv .swiper-slide {
        height: 230px;

    }
    

}

@media screen and (max-width:400px) {

    .guaranteeparentdiv {
        height: 100vh;
    }
    .maindivofg .swiper-slide {
        width: 20rem !important;
    }


}

@media screen and (max-width:375px) {

    .guaranteeparentdiv {
        height: 105vh;
    }


}