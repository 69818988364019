/* navbar css */

.collwaysnavbar {
    background-color: black;
    color: #fff;
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-inline: 18rem;
    margin-top: 6rem; */
    border-radius: 2rem;
    padding: 1.8rem;
    height: 65px;


}

.collwaysnavbar h3 {
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 2.7rem;

}

.collwaysimgnavbar {
    width: 50px;
}


#hiredtext{
    color: var(--orangecolor);
}



.menubaricon {
    display: none;
}

.uflex{
    list-style: none;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.uflex li a {
    text-decoration: none;
    font-size: 1.6rem;
    color: #fff;
    font-weight: 400;

}

.logodiv {
    display: flex;
    gap: 2rem;
}

.collwaysnavbar button {
    background-color: #fff;
    color: var(--orangecolor);
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 1.6rem;
    padding: 1rem 2rem;
    border: none;
    border-radius: 1.8rem;
    cursor: pointer;
}

.collwaysnavbar button:hover {
    background-color: var(--orangecolor);
    color: #fff;
}

.newuflex{
    background-color: ghostwhite;
    list-style: none;
    height: 400px;
    width: 366px;
    text-align: center;
    line-height: 2.2;
    font-size: 1.8rem;
    padding-top: 2rem;
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    right: 0;
    position: absolute;
    color: black;
    z-index: 10;
    transition: 0.5s;
    border-radius: 15px;

}

.newuflex li a{
    text-decoration: none;
    color: black;
    line-height: 3rem;
}

/* .menubaricon{
    z-index: 11;
    left: 125px;
    position: fixed;
   color: rgb(87, 87, 87);
  /* transform: translateX(-10px); */

  .menubaricon {
    z-index: 11;
    position: absolute;
    right: 30px;
    color: rgb(87, 87, 87);
}


a{
    text-decoration: none;
}


/* 6 */

@media screen and (max-width:1920px) {


   
    .uflex {
        gap: 4rem;
    }

}

@media screen and (max-width:1440px) {

    .uflex {
        gap: 2rem;
    }
}



@media screen and (max-width:1080px) {

    .headingandparagraphmaindiv {

        padding-bottom: 2rem;

    }


    .problemsolutionmaindiv {
        padding-inline: 1rem;
    }
}


@media screen and (max-width:1016px) {
    .navbarposi {
        position: fixed;
        width: 80%;
        top: 85vh;
        /* right: 10% !important; */
        z-index: 10;
    }
}


@media screen and (max-width:950px) {
    .collwaysnavbar {
        margin-inline: 1rem;
    }
}

@media screen and (max-width:700px) {
    .collwaysnavbar h3 {

        font-size: 2rem;
    }
}

@media screen and (max-width:600px) {
    .headingandparagraphmaindiv h1 {
        font-size: 5rem;
    }

    .collwaysnavbar h3 {

        display: none;
        
    }

}



@media screen and (max-width:900px) {
    .headingandparagraphmaindiv {
        padding-block: 2rem;
        gap: 3rem;
    }

    .uflex {
        height: 0px;
        width: 0px;
        overflow: hidden;
    }

    .menubaricon {
        display: block;
        font-size: 3rem;

 
   
    }

    .collwaysnavbar h3 {

        display: none;
    }

    .maindiv {
        padding-top: 3rem;

    }

    .headingandparagraphmaindiv h1 {
        font-size: 6rem;
    }
}


/* phone */

@media screen and (max-width:552px) {




    .maindiv {
        padding-top: 3rem;
    }

    .headingandparagraphmaindiv h1 {

        font-size: 6rem;

    }
}

@media screen and (max-width:513px) {

    .maindiv {
        padding-top: 3rem;
    }

    .headingandparagraphmaindiv h1 {
        font-size: 6rem;
    }

    .problemsolutionmaindiv h6 {
        font-size: 3rem;
    }

    .problemsolutionmaindiv p {
        font-size: 3rem !important;
    }

    .collwaysnavbar h3 {
        display: none;
    }

    .talentdiv h1 {
        font-size: 7rem !important;
    }
}


@media screen and (max-width:490px) {

    .maindiv .collwaysimg {
        height: 65px;
        width: 66px;
    }

    .headingandparagraphmaindiv h1 {
        font-size: 5rem;
    }

    .collwaysnavbar {
        margin-top: 3rem;
    }

    .problemsolutionmaindiv h6 {
        font-size: 2.5rem;
    }

    .problemsolutionmaindiv p {
        font-size: 2.5rem;
    }

    .talentstackdiv h1 {

        font-size: 5rem;
    }

    .allskillsdiv p {
        font-size: 1.5rem;
    }

    .problemsolutionmaindiv p {

        font-weight: 400;
        font-size: 2.5rem;
        line-height: 4.1rem;

    }

    .collwaysnavbar {
        display: flex;
        height: 65px;
        gap: 4rem;
    }

    .navbarposi {
        position: fixed;
        width: 80%;
        top: 85vh;
     
        z-index: 10;
    }

    .menubaricon {
        display: block;
        font-size: 3rem;
    
  

    }

}


@media screen and (max-width:430px) {

    .navbarposi {
        .navbarposi {
            position: fixed;
            width: 67%;
            top: 85vh;
            right: 16% !important;
            z-index: 10;
        }
    }


    .maindiv .collwaysimg {
        height: 50px;
        width: 90px;
    }

    .headingandparagraphmaindiv h1 {
        font-size: 4.5rem;
        padding-top: 3rem;
    }

    .collwaysnavbar {
        margin-top: 5rem;
    }

    .problemsolutionmaindiv h6 {
        font-size: 2.8rem !important;
        padding-inline: 2rem
    }

    .prolemright div {
        color: var(--orangecolor);
    }

    .problemsolutionmaindiv p {
        font-size: 2rem !important;
        line-height: 4rem !important;
    }

    .talentstackdiv h1 {

        font-size: 4rem !important;
    }

    .allskillsdiv p {
        font-size: 1.2rem !important;
    }

    .problemsolutionmaindiv p {

        font-weight: 400;
        font-size: 2rem;
        line-height: 4.1rem;

    }

    .talentdiv h1 {
        font-size: 5rem !important;
    }
}


@media screen and (max-width:400px) {

    .maindiv .collwaysimg {
        height: 35px;
        width: 55px;
    }

    .collwaysimgnavbar {
        width: 35px;
    }
    .menubaricon {
        display: block;
        font-size: 2.5rem;
    }

    .maindiv h1 {
        font-size: 3.5rem;
    }

    .problemsolutionmaindiv h6 {
        font-size: 2.2rem !important;
        line-height: 4rem !important;
    }
    .menubaricon {
        z-index: 11;
        /* position: fixed; */
        color: rgb(87, 87, 87);
        /* transform: translateX(-10px); */
    }

    
    .collwaysnavbar {
        margin-top: 2rem;
    }

}


@media screen and (max-width:330px) {

    .collwaysimgnavbar {
        display: none;
        }
        .menubaricon {
            z-index: 11;
            left: 37px;
    
        }

        .allskillsdiv p {
            font-size: 1.0rem !important;
        }
}


