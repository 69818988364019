.talentstackdiv {
  padding-block: 3rem;
  height: 109vh;
}

.talentstackdiv h1 {
  font-family: AeonikTRIAL-Regular;
  font-weight: 400;
  font-size: 9rem;
  color: var(--orangecolor);
  text-align: center;
  padding-block: 3rem;
}


.allskillsdiv {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  padding-top: 2rem;
}

.allskillsdiv p {
  font-family: AeonikTRIAL-Regular;
  font-weight: 400;
  font-size: 4rem;
  color: var(--orangecolor);
  border-radius: 20px;
  background: #FBE8DC;
  padding: 1.5rem;
}




.bottomlinehr {
  border-top: 1px solid #ECECEC !important;
  margin-top: 1rem;
  margin-inline: 5rem;
}


/* .marquee-container {
  overflow: hidden;
} */


.marquee-container {
  width: 100%;
  overflow-x: hidden;
}


.addanimation1 {
  display: flex;
  animation: marquee-scroll2 20s linear infinite;

}

@keyframes marquee-scroll2 {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}


.addanimation2 {
  display: flex;
  /* border: 1px solid orange; */
  animation: marquee-scrollmidd 15s linear infinite;

}

@keyframes marquee-scrollmidd {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}


.addanimation03 {
  display: flex;
  /* border: 1px solid red; */
  animation: marquee-scrollm03 20s linear infinite;

}

@keyframes marquee-scrollm03 {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}






@media screen and (max-width:1920px) {


  .talentstackdiv h1 {

    font-size: 10rem;

  }

  .allskillsdiv p {

    padding: 2.5rem;
  }

  .allskillsdiv {

    padding-top: 6rem;
  }

}



@media screen and (max-width:1440px) {




  .talentstackdiv h1 {

    font-size: 9rem;

  }

  .allskillsdiv p {

    padding: 1.5rem;
  }

  .allskillsdiv {

    padding-top: 2rem;
  }


}


@media screen and (max-width:1550px) {




  .talentstackdiv h1 {

    font-size: 9rem;

  }

  .allskillsdiv p {

    padding: 1.5rem;
  }

  .allskillsdiv {

    padding-top: 1rem;
  }


}




@media screen and (max-width:1025px) {
  .talentstackdiv h1 {
    font-size: 8rem;
    padding-block: 2rem;
  }

  .talentstackdiv {
    padding-block: 0rem;
  }

  .allskillsdiv p {
    font-size: 3rem;
    padding: 1rem;
  }

  .marquee-container {
    margin-top: 2rem;
  }

}

@media screen and (max-width:700px) {

  .talentstackdiv h1 {
    font-size: 6rem;
  }

  .allskillsdiv p {
    font-size: 2rem;
    padding: 1rem;
  }


}

@media screen and (max-width:850px) {

  .talentstackdiv {
    height: 60vh;

}


}



/* phone media querires */

@media screen and (max-width:500px) {

  .talentstackdiv {
    padding-block: 5rem;
    height: 60vh;
  }

  .allskillsdiv {
    gap: 1rem;
  }

  .talentstackdiv h1 {
    font-size: 4rem;
  }

  .bottomlinehr {
    border-top: 1px solid #ECECEC !important;
    margin-top: 1rem;
    margin-inline: 0rem;
  }

  .allskillsdiv p {
    font-size: 1.6rem;
    padding: 5px;
  }


}

@media screen and (max-width:470px) {

  .talentstackdiv {
    padding-block: 5rem;
    height: 60vh;
  }

  .allskillsdiv {
    gap: 1rem;
  }

  .talentstackdiv h1 {
    font-size: 4rem;
  }

  .bottomlinehr {
    border-top: 1px solid #ECECEC !important;
    margin-top: 1rem;
    margin-inline: 0rem;
  }

  .allskillsdiv p {
    font-size: 1.6rem !important;
    padding: 5px !important;
  }


}

@media screen and (max-width:400px) {

  .talentstackdiv {
    padding-block: 5rem;
    height: 65vh;
  }
}


@media screen and (max-width: 350px)
{
  .talentstackdiv {
    padding-block: 5rem;
    height: 74vh;
}
}