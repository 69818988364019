.problemsolutionmaindiv {
    background-color: #ffff;
    /* height: 100vh; */
    padding-block: 5rem;
    padding-inline: 6rem;
}

.problemcontent{
    padding-top: 1.5rem;
}

.problemcontent h1{
    color: var(--orangecolor);
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 6rem;
}

.problemcontent h2{
    color: black;
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 3rem;
}

.solutionmaindiv{
    background-color: var(--orangecolor);
    color: white;
    padding: 4rem;
    border-radius: 1.5rem;
    margin-top: 5rem;
}


.solutionmaindiv h2{
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 5.5rem;
    color: #fff;
}

.solutionmaindiv h1{
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 4rem;
    padding-top: 1rem;
}



@media screen and (max-width:1920px) {

    .solutionmaindiv h1{
        font-size: 5.5rem;
    }

    .problemcontent h1{
        font-size: 8rem;
    }
    
    .problemcontent h2{
        font-size: 4rem;
    }
}

@media screen and (max-width:1400px) {

    .solutionmaindiv h1{
        font-family: AeonikTRIAL-Regular;
        font-weight: 400;
        font-size: 4rem !important ;
        padding-top: 1rem;
    }
    .problemcontent h1{
        font-size: 6rem;
    }
    
    .problemcontent h2{
        font-size: 3.5rem;
    }
}

@media screen and (max-width:1550px) {

    .solutionmaindiv h1{
        font-size: 5rem;
    }
    .problemcontent h1{
        font-size: 6rem ;
    }
    
    .problemcontent h2{
        font-size: 4rem ;
    }
}


@media screen and (max-width:500px) {

    .problemsolutionmaindiv{
        padding-inline: 2rem;
        padding-top: 5rem;
    }

    .solutionmaindiv h1{
        font-size: 2rem !important;
    }
    .problemcontent h1{
        font-size: 5rem ;
    }
    
    .problemcontent h2{
        font-size: 2rem ;
    }
}

