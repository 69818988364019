.faizanprofilemaindiv {
    background: linear-gradient(77deg, #E9620B 0%, rgba(233, 98, 11, 0.55) 100%);
    height: 100vh;

}

.rightarrowandspan {
    width: 145px;
    display: flex;
    align-items: center;
}

.rightarrowandspan img {
    width: 30px;
}

.mainflex {
    display: flex;
    /* flex-wrap: wrap; */
}


.maincontent {
    padding-top: 6rem;
    padding-left: 5rem;
    width: 60%;
}

.maincontent h1 {
    font-family: AeonikTRIAL-Regular;
    font-weight: 700;
    font-size: 7rem;
    color: #fff;

}

.maincontent h2 {
    font-family: AeonikTRIAL-Regular;
    font-weight: 700;
    font-size: 4rem;
    color: #fff;
    padding-block: 1rem;

}

.maincontent h3 {
    font-family: AeonikTRIAL-Bold;
    font-weight: 700;
    font-size: 3rem;
    color: #fff;

}

.maincontent h4 {
    font-family: AeonikTRIAL-Bold;
    font-size: 2.5rem;
    color: #fff;
    padding-block: 1rem;

}


.mainskillsetdiv {
    padding-block: 1rem;
    display: flex;
    color: #fff;
    align-items: center;
    gap: 1rem;
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 1.4rem;

}

.maincontent #maincontentparagraph {

    font-family: AeonikTRIAL-Bold;
    font-weight: 400;
    font-size: 1.8rem;
    color: #fff;
    transform: rotate(-0.079deg);
}


.skillexperiencediv{
   
    height: 100vh;
}


.skilldiv h5 {
    background-color: #F3AB7B;
    color: #fff;
    padding: 0.8rem 1rem;
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 1.6rem;
    border-radius: 20px;
}

.skilldiv button {
    padding: 0.7rem 2rem;
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 1.8rem;
    border-radius: 20px;
    color: var(--orangecolor);
    background-color: #ffff;
    border: none;
    cursor: pointer;
}

.faizanimg img {
    width: 674px;
    height: 100vh;
    transform: translateX(98px);
}



.recandh3maindiv{
    display: flex;
    align-items: center;
    gap: 2rem;
}


.recandh3maindiv h3{
    color: var(--orangecolor);
    font-family: AeonikTRIAL-Bold;
    font-size: 1.4rem;
}








/* modal css */

.mainskillsetdiv .modal-content {
    height: 80vh;
    background-color: #fff;
    width: 75%;
    border-radius: 20px;
    opacity: 1;
}



.mainskillsetdiv .theskills h1 {
    color: var(--orangecolor);
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    text-align: center;
    padding-top: 3rem;
}


.skillsmaindivslide {
    padding-top: 3rem;


}

.faizanprofilemaindiv p {
    font-family: AeonikTRIAL-Regular;
    font-weight: 400;
    font-size: 2.5rem;
    color: var(--orangecolor);
    border-radius: 20px;
    padding: 1.5rem 1.7rem;
}




#experiencemaindivpara {
    color: black;
    padding-top: 2rem;
}


.mainflexdiv{
    display: flex;
    flex-wrap: wrap;
   
    justify-content: space-between;
    padding-inline: 15rem;
    padding-top: 4rem;
}

.experiencecontentmaindiv{
    width: 30%;
}

.experienceheading{
    text-align: left !important;
    padding-left: 10rem;
}

.experiencecontentmaindiv h2{
    font-family: AeonikTRIAL-Bold;
    font-size: 1.6rem;
}

.experiencecontentmaindiv p{
    color: black;
    font-family: AeonikTRIAL-Regular;
    font-size: 1.6rem;
}

/* modal css */









@media(max-width:1920px) {
    .maincontent #maincontentparagraph {
        font-size: 2.5rem;
        line-height: 1.5;
    }

    .maincontent h1 {
        font-size: 10rem;
    }

    .maincontent h2 {
        font-size: 6rem;
    }

    .maincontent h3 {
        font-size: 5rem;
    }

    .skilldiv h5 {
        padding: 1.2rem 4rem;
        font-size: 2rem;
    }

    .skilldiv button {
        padding: 1.2rem 4rem;
        font-size: 2rem;
    }

    .mainskillsetdiv .modal-content {
        margin-top: -20rem;
    }

}

@media(max-width:1600px) {
    .maincontent #maincontentparagraph {
        font-size: 1.8rem;
    }

    .maincontent h1 {
        font-size: 8rem;
    }

    .maincontent h2 {
        font-size: 5rem;
    }

    .maincontent h3 {
        font-size: 4rem;
    }

    .skilldiv h5 {
        padding: 1.1rem 2.5rem;
        font-size: 1.6rem;
    }

    .skilldiv button {
        padding: 1.1rem 2.5rem;
        font-size: 1.6rem;
    }

    .mainskillsetdiv .modal-content {
        margin-top: 0rem;
    }


}















@media(max-width:1400px) {
    .maincontent #maincontentparagraph {
        font-size: 1.8rem;
    }

    .maincontent h1 {
        font-size: 7rem;
    }

    .maincontent h2 {
        font-size: 4rem;
    }

    .maincontent h3 {
        font-size: 3rem;
    }

    .mainskillsetdiv .modal-content {
        margin-top: 0rem;
    }

    .skilldiv h5 {
        padding: 1rem 2rem;
        font-size: 1.4rem;
    }

}


@media(max-width:850px) {
    .mainflexdiv {
        padding-inline: 5rem;

    }

    .maincontent {
        width: 100%;

    }

    .faizanimg {
        width: 100%;
    }

    .mainflex {
        flex-direction: column;
    }

    .faizanimg {
        width: 100%;
    }

    .faizanimg img {
        width: 72%;
        height: 100vh;
        transform: translateX(98px);
    }
    
}


@media(max-width:768px) {
    .maincontent {
        width: 100%;

    }

    .faizanimg {
        width: 100%;
    }

    .mainflex {
        flex-direction: column;
    }

    .faizanimg {
        width: 100%;
    }

    .faizanimg img {
        width: 72%;
        height: 100vh;
        transform: translateX(98px);
    }
}



@media(max-width:430px) {
    .faizanimg {
        order: -1;

        background-image: linear-gradient(224.8deg, #F3AC7F 1.74%, #FBE8DC 100%);
        border-radius: 20px;
        height: 45vh;
    }

    .faizanprofilemaindiv {

        height: 130vh;

    }


    .faizanimg img {
        order: -1;
        width: 100%;
        height: 45vh;
        justify-content: center;
        /* background-color:white; */
        /* background-color:linear-gradient(224.8deg, #F3AC7F 1.74%, #FBE8DC 100%); */
        display: flex;
        align-items: center;
        object-fit: contain;
        border-radius: 20px;
        transform: translate(0px) !important;
    }

    .maincontent h1 {
        font-size: 3rem;
    }

    .maincontent h2 {
        font-size: 1.5rem;
        font-weight: 400;
    }

    .maincontent h3 {
        font-size: 1.5rem;
        font-weight: 400;
    }

    .mainarrowdiv {
        /* flex-direction: column; */
        align-items: flex-start;
        gap: 5px;
    }

    .mainarrowdiv button {
        font-size: 10px;
    }

    .maincontent {
        width: 100%;
        padding-left: 0rem;
        padding-top: 0rem;
    }

    .faizanimg {
        width: 100%;
    }

    .mainflex {
        flex-direction: column;
        padding: 20px;
        height: 115vh;
    }
}


@media screen and (max-width:500px) {
    .skillexperiencediv h1 {
        font-size: 4rem !important;
    }

   
    .faizanimg {
        order: -1;

        background-image: linear-gradient(224.8deg, #F3AC7F 1.74%, #FBE8DC 100%);
        border-radius: 20px;
        height: 45vh;
    }

    .faizanprofilemaindiv {

        height: 130vh;

    }


    .faizanimg img {
        order: -1;
        width: 100%;
        height: 45vh;
        justify-content: center;
        /* background-color:white; */
        /* background-color:linear-gradient(224.8deg, #F3AC7F 1.74%, #FBE8DC 100%); */
        display: flex;
        align-items: center;
        object-fit: contain;
        border-radius: 20px;
        transform: translate(0px) !important;
    }

    .maincontent h1 {
        font-size: 3rem;
    }

    .maincontent h2 {
        font-size: 1.5rem;
        font-weight: 400;
    }

    .maincontent h3 {
        font-size: 1.5rem;
        font-weight: 400;
    }

    .mainarrowdiv {
        /* flex-direction: column; */
        align-items: flex-start;
        gap: 5px;
    }

    .mainarrowdiv button {
        font-size: 10px;
    }

    .maincontent {
        width: 100%;
        padding-left: 0rem;
        padding-top: 0rem;
    }

    .faizanimg {
        width: 100%;
    }

    .mainflex {
        flex-direction: column;
        padding: 20px;
        height: 115vh;
    }
}

    .skilldiv h5 {
        padding: 0.7rem 1.6rem;
        font-size: 1.4rem;
    }

    .skilldiv button {
        padding: 0.7rem 1.6rem;
        font-size: 1.4rem;
    }

    /* .mainskillsetdiv .modal-content {
   
        width: 100% !important;
    } */

    .modal {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 0;
        /* top: -343px; */

    }


    

   
    @media screen and (max-width:500px) {
        .skillexperiencediv h1 {
            font-size: 4rem !important;
        }
    
        .experiencecontentmaindiv{
           
            width: 100%;
            padding-top: 2rem;
        }
    
        .mainflex {
            flex-direction: column;
            padding: 20px;
            height: 130vh;
        }
    
        .skilldiv h5 {
            padding: 0.5rem 1.2rem;
            font-size: 1.2rem;
        }
    
        .skilldiv button {
            padding: 0.5rem 1.2rem;
            font-size: 1.2rem;
        }
    
        /* .mainskillsetdiv .modal-content {
            margin-top: -15rem;
        }
     */
        .modal {
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            left: 0;
            /* top: -343px; */
    
        }
    
        .skillexperiencediv .processmaindiv {
            height: 120vh;
        }
    
        .skillexperiencediv .lightborder {
            display: flex;
            justify-content: left;
    
        }
    
        .skillexperiencediv #thirdlightborder {
            display: none;
        }
    }
    

@media screen and (max-width:400px) {
    .skillexperiencediv h1 {
        font-size:  4rem !important;
    }

    .experiencecontentmaindiv{
       
        width: 100%;
        padding-top: 2rem;
    }

    .mainflex {
        flex-direction: column;
        padding: 20px;
        height: 130vh;
    }

    .skilldiv h5 {
        padding: 0.5rem 1.2rem;
        font-size: 1.2rem;
    }

    .skilldiv button {
        padding: 0.5rem 1.2rem;
        font-size: 1.2rem;
    }

    .mainskillsetdiv .modal-content {
        margin-top: 5rem;
  
    }

    .modal {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 0;
        /* top: -343px; */

    }

    .skillexperiencediv .processmaindiv {
        height: 120vh;
    }

    .skillexperiencediv .lightborder {
        display: flex;
        justify-content: left;

    }

    .skillexperiencediv #thirdlightborder {
        display: none;
    }
}
